<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard">
        <div class="postercard__img">
            <img :src="'http://127.0.0.1:8000/uploads/media/page/'">
        </div>
    </div>
    <section class=" blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li>
                                <router-link :to="{name:'Home'}">Home</router-link>
                            </li>
                            <li><span class="live">Booking Confirm</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">Thank You {{ $route.params.fullname }}</h1>
                    <div class="reviser">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut urna at tellus tempor lacinia. Nunc egestas nec enim at tempus. Integer id tristique lectus. Fusce porta lectus lorem, vitae consequat felis interdum eu. In quis tortor ut felis iaculis euismod ut vel nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam finibus tristique vehicula. Donec tincidunt ligula nec diam dapibus, ac tincidunt risus feugiat. Etiam gravida augue a tempus tempor. Donec turpis purus, ornare at augue ac, ullamcorper interdum augue. Praesent sit amet quam elit.</p>
                        <p>Aenean blandit fringilla nisi, eget hendrerit nisl condimentum eget. Integer sit amet est quis nisi pellentesque elementum consectetur eu magna. Curabitur rhoncus ante non dui venenatis, vel feugiat eros ultrices. Pellentesque congue dolor feugiat pellentesque iaculis. Fusce varius volutpat nisl facilisis imperdiet. Mauris eget urna ac est vestibulum pellentesque. Aenean lacinia magna quis augue fringilla, at lacinia quam porta. Fusce eu dui a orci dapibus gravida. Maecenas commodo metus vitae nulla venenatis euismod. Sed cursus vestibulum nunc, eleifend vestibulum sem condimentum ut. Sed lorem risus, scelerisque lacinia diam pellentesque, porta dignissim erat.</p>
                        <form class="form-horizontal" method="POST" action="https://hblpgw.2c2p.com/HBLPGW/Payment/Payment/Payment">
                            <div class="form-action">
                                <input type="hidden" id="paymentGatewayID" name="paymentGatewayID" v-model="paymentGatewayId">
                                <input type="hidden" id="invoiceNo" name="invoiceNo" v-model="invoiceNo">

                                <input type="hidden" id="amount" name="amount" v-model="amount">
                                <input type="hidden" id="currencyCode" name="currencyCode" v-model="currencyCode">
                                <input type="hidden" id="userDefined1" name="userDefined1" v-model="userDefined1">
                                <input type="hidden" id="productDesc" name="productDesc" v-model="productDesc">
                                <input type="hidden" id="nonSecure" name="nonSecure" value="N">
                                <input type="hidden" id="hashValue" name="hashValue" v-model="hashkey">
                                <input type="submit" class="btn btn-secondary" value="Proceed for Payment">
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">

                    <div class="related">
                        <h2 class="related__title"><strong>Awesome</strong> Package</h2>
                        <awesome />

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Thank You',
    data() {
        return {
            paymentGatewayId: 9103336079,
            invoiceNo: null,
            productDesc: this.$route.params.packageName,
            amount: this.$route.params.depositAmount + '00',
            currencyCode: 840,
            userDefined1: this.$route.params.fullname,
            apiPath:process.env.VUE_APP_API,
            hashkey:null,
        }
    },
    mounted(){
        

        // console.warn(pad('00000000000000000000',Date.now()));
        this.invoiceNo=invoicepad('00000000000000000000',Date.now());
        function invoicepad(mainval,value){
            return (mainval + value).slice(-20);
        }
        this.amount=amt('000000000000',this.$route.params.depositAmount*100);
        function amt(mainval,value){
            return (mainval + value).slice(-12);
        }
        this.gethashvalue();
        
    },
    methods:{
        async gethashvalue(){
            axios.post(this.apiPath+'hash',{
                amount:this.$route.params.depositAmount,
                invoiceNo,
            }).then((response)=>{
                console.log(response.data);
                this.hashkey=response.data;
            });
            // console.log("result",result);
        }
    }
}
</script>
